/* General Styling */
.id-card-container {
    width: 330px;
    height: 204px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    color: #fff;

    background-image: url("../../../public/assets/layout/images/card_back.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/* Front and Back Side */
.id-card-front{
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
.loyalty-card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #f82474;
}
.id-card-back {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Full page */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
.id-card-front .reference {
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
    font-weight: 400;
}
.id-card-barcode {
    height: 45px;
    margin-top: auto;
    margin-bottom: 3px;
}
.product-image {
    display: block;
    /* margin: 0 auto; */
    margin-top: 1rem;
    height: 70px;

    width: 200px; 
    border-radius: 8px; /* Rounded corners */
    opacity: 0.8; /* Blend with background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    background-color: rgba(255, 255, 255, 0.5);
}
.text-white {
    color: #fff;
}
.text-reference {
    color: #000;
    opacity: 0.7;
}
/* Back Side Company Logo and Info */
.company-logo {
    width: 100px;
    margin-bottom: 10px;
}

.id-card-back p {
    margin: 4px 0;
    font-size: 10px;
    text-align: center;
    line-height: 1.3;
    word-wrap: break-word;
    color: #000;
}

.id-card-back strong {
    font-size: 16px;
}

.disclaimer {
    font-size: 10px;
    text-align: center;
    color: #f82474;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-style: italic;
    word-wrap: break-word; 
}

/* Print Styling */
@media print {
    body {
        margin: 0;
        padding: 0;
    }
    html, body {
        width: 100%;
        height: 100%;
    }
    #print-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transform: translate(0, 0); 
    }

    canvas {
        margin: 0 auto; /* Ensure the canvas is centered */
    }
    .non-printable {
        display: none;
    }
    .print-page {
        page-break-after: always;
    }
    /* .id-card-container {
        width: auto;
        height: 336px;
    } */
    .id-card-container {

    padding-left: 20px;
        width: 330px; /* Match the intended width */
        height: 204px; /* Match the intended height */
        margin: 0; /* Remove extra margins for printing */
    }
}
