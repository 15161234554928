.table-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.table-badge.status-true {
  background: #c8e6c9;
  color: #256029;
}

.table-badge.status-false {
  background: #ffcdd2;
  color: #c63737;
}

.table-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}
